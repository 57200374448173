import React, { useState, useEffect } from "react"
import { inject, observer } from "mobx-react"

const MoneyOut = observer(
  ({ creditCardsList, wallet, moneyOut, operationComplite, onClose }) => {
    const [amount, setAmount] = useState("0")
    const [recToken, setRecToken] = useState("")
    const [isDisabled, setIsDisabled] = useState(true)

    const chooseCard = (e) => {
      setRecToken(e.target.value)
      if (amount >= 1 && e.target.value.length > 0 && wallet) {
        setIsDisabled(false)
      } else {
        if (!isDisabled) {
          setIsDisabled(true)
        }
      }
    }

    const chgAmount = (e) => {
      if (
        /^(?=.*\d)\d*(?:\.\d{0,2})?$/.test(e.target.value) ||
        e.target.value === ""
      ) {
        setAmount(e.target.value)
        if (e.target.value >= 1 && recToken.length > 0 && wallet) {
          setIsDisabled(false)
        } else {
          if (!isDisabled) {
            setIsDisabled(true)
          }
        }
      }
    }

    const tryMoneyOut = () => {
      moneyOut(
        wallet,
        amount,
        recToken,
        `Withdraw funds from the wallet ${wallet}`
      )
    }

    useEffect(() => {
      if (operationComplite) {
        onClose()
      }
    }, [operationComplite, onClose])

    const ccList = (
      <select value={recToken} onChange={chooseCard} className="form-control">
        <option value="" key={0}></option>
        {creditCardsList.map((card) => (
          <option value={card.recToken} key={card.id}>
            {card.cardPan}
          </option>
        ))}
      </select>
    )

    return (
      <>
        <div className="row">
          <div className="col">
            {creditCardsList.length > 0 ? (
              <>{ccList}</>
            ) : (
              <>First you need to add a credit card</>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col">
            <input
              type="text"
              value={amount}
              onChange={chgAmount}
              className="form-control"
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <button
              className="btn btn-primary btn-block"
              onClick={tryMoneyOut}
              disabled={isDisabled}
            >
              Withdraw
            </button>
          </div>
        </div>
      </>
    )
  }
)

const withData = inject((stores) => ({
  creditCardsList: stores.CreditsCardsStore.creditCardsList,
  moneyOut: stores.WalletsStore.moneyOut,
  operationComplite: stores.WalletsStore.operationComplite,
}))

export default withData(MoneyOut)
