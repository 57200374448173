import AuthStore from "./pages/LoginPage/mobx"
import WalletsStore from "./components/Wallets/mobx"
import CreditsCardsStore from "./components/CreditsCards/mobx"
import ProductStore from "./components/Products/mobx"

export default {
  AuthStore,
  WalletsStore,
  CreditsCardsStore,
  ProductStore,
}
