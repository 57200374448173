import React, { useState, useEffect } from "react"
import { inject, observer } from "mobx-react"
import Iframe from "react-iframe"

import { authApi } from "../../../configs/api"

const LiqPayOnCard = observer(({ wallet, liqpayOnCard }) => {
  const [amount, setAmount] = useState(0)
  const [cardNumber, setCardNumber] = useState("")
  const [isDisabled, setIsDisabled] = useState(true)

  const chgCardNumber = (e) => {
    setCardNumber(e.target.value)
    if (e.target.value.length === 16 && amount > 0) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }

  const moneyOnCard = () => {
    liqpayOnCard(wallet, amount, "Output on card", cardNumber)
  }

  const chgAmount = (e) => {
    if (
      /^(?=.*\d)\d*(?:\.\d{0,2})?$/.test(e.target.value) ||
      e.target.value === ""
    ) {
      setAmount(e.target.value)
      if (cardNumber.length === 16 && e.target.value > 0) {
        setIsDisabled(false)
      } else {
        setIsDisabled(true)
      }
    }
  }

  return (
    <>
      <div className="row">
        <div className="col">
          <input
            type="text"
            value={amount}
            onChange={chgAmount}
            className="form-control"
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <input
            type="text"
            value={cardNumber}
            onChange={chgCardNumber}
            className="form-control"
            placeholder="Enter card number"
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <button
            className="btn btn-primary btn-block"
            onClick={moneyOnCard}
            disabled={isDisabled}
          >
            Output on card
          </button>
        </div>
      </div>
    </>
  )
})

const withData = inject((stores) => ({
  liqpayOnCard: stores.WalletsStore.liqpayOnCard,
}))

export default withData(LiqPayOnCard)
