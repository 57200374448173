import React, { useState, useEffect } from "react"
import { inject, observer } from "mobx-react"

const WayForPay = observer(
  ({ creditCardsList, refillWallet, operationComplite, onClose }) => {
    const [recToken, setRecToken] = useState("")
    const [isDisabled, setIsDisabled] = useState(true)

    const chooseCard = (e) => {
      setRecToken(e.target.value)
      if (e.target.value.length > 0) {
        setIsDisabled(false)
      }
    }

    const tryRefillWallet = () => {
      refillWallet(5, 1, recToken, `Buy product`)
    }

    useEffect(() => {
      if (operationComplite) {
        onClose()
      }
    }, [operationComplite, onClose])

    const ccList = (
      <select value={recToken} onChange={chooseCard} className="form-control">
        <option value="" key={0}></option>
        {creditCardsList.map((card) => (
          <option value={card.recToken} key={card.id}>
            {card.cardPan}
          </option>
        ))}
      </select>
    )

    return (
      <>
        <div className="row">
          <div className="col">
            {creditCardsList.length > 0 ? (
              <>{ccList}</>
            ) : (
              <>First you need to add a credit card</>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col">
            <button
              className="btn btn-primary btn-block"
              onClick={tryRefillWallet}
              disabled={isDisabled}
            >
              Buy
            </button>
          </div>
        </div>
      </>
    )
  }
)

const withData = inject((stores) => ({
  creditCardsList: stores.CreditsCardsStore.creditCardsList,
  refillWallet: stores.WalletsStore.refillWallet,
  operationComplite: stores.WalletsStore.operationComplite,
}))

export default withData(WayForPay)
