import React from "react"
import { BrowserRouter, Switch, Route } from "react-router-dom"

import LoginPage from "./pages/LoginPage"
import MainPage from "./pages/MainPage"

const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" component={LoginPage} exact />
        <Route path="/dashboard" component={MainPage} />
      </Switch>
    </BrowserRouter>
  )
}

export default App
