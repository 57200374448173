import { observable, action } from "mobx"
import Http from "../../helpers/http"
import { authApi } from "../../configs/api"

class AuthStore {
  @observable isLoading = false
  @observable isLogin = null
  @observable isError = false
  @observable errorMsg = null

  @action
  tryAuth = async (email, password) => {
    try {
      this.isLoading = true
      const response = await Http.post(`${authApi}login`, { email, password })
      if (response.message) {
        this.errorMsg = response.message
        this.isError = true
      } else {
        localStorage.setItem("token", response.token)
        this.isLogin = true
      }
      this.isLoading = false
    } catch (e) {
      this.isLoading = false
      this.errorMsg = e
      this.isError = true
    }
  }

  @action
  logout = () => {
    localStorage.removeItem("token")
    this.isLogin = false
  }

  @action
  relogin = () => {
    const token = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : false
    if (!token) {
      return false
    }

    if (token.length > 1) {
      return true
    }
    return false
  }

  @action
  createAccount = async (email, password) => {
    try {
      this.isLoading = true
      const response = await Http.post(`${authApi}signin`, { email, password })
      this.isLoading = false

      if (response.message === "User allready exist.") {
        this.errorMsg = "Такой пользователь уже существует"
        this.isError = true
        return false
      }

      this.errorMsg = ""
      this.isError = false

      return response.id ? true : false
    } catch (e) {
      this.isLoading = false
      this.errorMsg = e
      this.isError = true
    }
  }
}

export default new AuthStore()
