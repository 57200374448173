import React from "react"
import { Drawer } from "antd"
import { Link } from "react-router-dom"

import "antd/dist/antd.css"
import "./DrawerMenu.scss"

const DrawerMenu = (props) => {
  const { onClose, status } = props
  return (
    <Drawer
      placement="left"
      closable={false}
      onClose={onClose}
      visible={status}
      className="drawer-menu"
    >
      <Link to="/wallets">Wallets</Link>
      <Link to="/history">Transaction history</Link>
      <Link to="/users">Users</Link>
    </Drawer>
  )
}

export default DrawerMenu
