import { observable, action } from "mobx"
import Http from "../../helpers/http"
import { authApi } from "../../configs/api"

class CreditsCardsStore {
  @observable isLoading = false
  @observable isError = false
  @observable errorMsg = null
  @observable creditCardList = []
  @observable addCcForm = ""

  @action
  getCreditCards = async () => {
    try {
      this.isLoading = true
      const response = await Http.get(`${authApi}v1/wayforpay/my-cards`, true)
      this.creditCardsList = response.result
      this.isLoading = false
      this.isError = false
      this.errorMsg = null
    } catch (e) {
      this.isLoading = false
      this.isError = true
      this.errorMsg = e
    }
  }

  @action
  addCreditCard = async () => {
    try {
      const response = await Http.get(`${authApi}v1/wayforpay/addcard`, true)
      const { formCode } = response
      if (formCode) {
        this.addCcForm = formCode
      }
    } catch (e) {
      this.isError = true
      this.errorMsg = e
    }
  }

  @action
  deleteCard = async (id) => {
    try {
      const response = await Http.delete(
        `${authApi}v1/wayforpay/deleteCard?cid=${id}`,
        true
      )
      if (response.status === "Success") {
        this.getCreditCards()
      }
    } catch (e) {
      this.isError = true
      this.errorMsg = e
    }
  }
}

export default new CreditsCardsStore()
