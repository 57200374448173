import React, { Component } from "react"
import { inject, observer } from "mobx-react"
import { BsGift } from "react-icons/bs"
import { Modal, Tabs } from "antd"
import Iframe from "react-iframe"

import WayForPay from "./WayForPay"

import { authApi } from "../../configs/api"

import "./Products.scss"

const { TabPane } = Tabs

@observer
class Products extends Component {
  state = {
    showModal: false,
  }

  buy = () => {
    this.props.buyProduct()
  }

  toggleModal = () => {
    this.setState({
      showModal: false,
    })
  }

  buyProduct = () => {
    this.setState({
      showModal: true,
    })
  }

  render() {
    const tokenForQuery = localStorage.getItem("token").split(" ")

    return (
      <>
        <div className="col-3 product" onClick={this.buyProduct}>
          <div className="product-wrapper">
            <div className="product-icon">
              <BsGift />
            </div>
            <span className="poduct-price">1 UAH</span>
          </div>
        </div>
        <div className="col-3 product" onClick={this.buyProduct}>
          <div className="product-wrapper">
            <div className="product-icon">
              <BsGift />
            </div>
            <span className="poduct-price">1 UAH</span>
          </div>
        </div>
        <Modal
          title="Buy product"
          visible={this.state.showModal}
          onCancel={this.toggleModal}
          footer={null}
          className="put-it-wallet"
        >
          <Tabs defaultActiveKey="1" type="card">
            <TabPane tab="WayForPay" key="1">
              <WayForPay />
            </TabPane>
            <TabPane tab="LiqPay" key="2">
              {console.log(
                `${authApi}v1/liqpay/payment?token=${tokenForQuery[1]}&comment=Buy product&wallet=5&amount=1`
              )}
              <Iframe
                url={`${authApi}v1/liqpay/payment?token=${tokenForQuery[1]}&comment=Buy product&wallet=5&amount=1`}
                className="iframeBlock"
              />
            </TabPane>
          </Tabs>
        </Modal>
      </>
    )
  }
}

const withData = inject((stores) => ({
  payLiqpay: stores.ProductStore.payLiqpay,
}))

export default withData(Products)
