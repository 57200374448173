import React, { useState, useEffect } from "react"
import { inject, observer } from "mobx-react"
import { GiTwoCoins, GiReceiveMoney, GiWallet } from "react-icons/gi"
import { Modal, Table, Tabs } from "antd"

import LiqPayPayment from "./LiqPay/LiqPayPayment"
import LiqPayOnCard from "./LiqPay/LiqPayOnCard"
import PutInWallet from "./PutInWallet"
import MoneyOut from "./MoneyOut"
import WalletToWallet from "./WalletToWallet"

import "./Wallets.scss"

const { TabPane } = Tabs

const Wallets = observer(
  ({ walletsList, isLoading, getWallets, createWallet }) => {
    const [showModal, setShowModal] = useState(false)
    const [showModalMoneyOut, setShowModalMoneyOut] = useState(false)
    const [targetWallet, setTargetWallet] = useState(false)
    const [showModalToWallet, setShowModalToWallet] = useState(false)

    const handleCancel = () => {
      setShowModal(false)
      setShowModalMoneyOut(false)
      setShowModalToWallet(false)
    }

    const putInWallet = (walletId) => {
      setTargetWallet(walletId)
      setShowModal(true)
    }

    const outFromWallet = (walletId) => {
      setTargetWallet(walletId)
      setShowModalMoneyOut(true)
    }

    const walletToWallet = (walletId) => {
      setTargetWallet(walletId)
      setShowModalToWallet(true)
    }

    const columns = [
      {
        title: "Wallet",
        dataIndex: "wNumber",
      },
      {
        title: "Balance",
        dataIndex: "balance",
      },
      {
        title: "",
        render: (record) => {
          return (
            <div className="wallet-control">
              <div className="wallet-icons">
                <GiTwoCoins onClick={() => putInWallet(record.id)} />
              </div>
              <div className="wallet-icons">
                <GiReceiveMoney onClick={() => outFromWallet(record.id)} />
              </div>
              <div className="wallet-icons">
                <GiWallet onClick={() => walletToWallet(record.id)} />
              </div>
            </div>
          )
        },
      },
    ]

    useEffect(() => {
      getWallets()
    }, [getWallets])

    return (
      <div className="wallets-list">
        <div className="row">
          <div className="col">
            <button className="btn btn-info" onClick={createWallet}>
              Create wallet
            </button>
            <button
              className="btn btn-secondary"
              onClick={getWallets}
              style={{ marginLeft: "20px" }}
            >
              Refresh
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Table
              columns={columns}
              dataSource={walletsList}
              loading={isLoading}
              rowKey={(record) => record.id}
            />
          </div>
        </div>
        <Modal
          title="Replenish"
          visible={showModal}
          onCancel={handleCancel}
          footer={null}
          className="put-it-wallet"
        >
          <Tabs defaultActiveKey="1" type="card">
            <TabPane tab="WayForPay" key="1">
              <PutInWallet wallet={targetWallet} onClose={handleCancel} />
            </TabPane>
            <TabPane tab="LiqPay" key="2" forceRender={true}>
              <LiqPayPayment wallet={targetWallet} />
            </TabPane>
          </Tabs>
        </Modal>
        <Modal
          title="Output on card"
          visible={showModalMoneyOut}
          onCancel={handleCancel}
          footer={null}
          className="put-it-wallet"
        >
          <Tabs defaultActiveKey="1" type="card">
            <TabPane tab="WayForPay" key="1">
              <MoneyOut wallet={targetWallet} onClose={handleCancel} />
            </TabPane>
            <TabPane tab="LiqPay" key="2" forceRender={true}>
              <LiqPayOnCard wallet={targetWallet} />
            </TabPane>
          </Tabs>
        </Modal>
        <Modal
          title="Transfer from wallet to wallet"
          visible={showModalToWallet}
          onCancel={handleCancel}
          footer={null}
          className="put-it-wallet"
        >
          <WalletToWallet wallet={targetWallet} />
        </Modal>
      </div>
    )
  }
)

const withData = inject((stores) => ({
  walletsList: stores.WalletsStore.walletsList,
  isLoading: stores.WalletsStore.isLoading,
  getWallets: stores.WalletsStore.getWallets,
  createWallet: stores.WalletsStore.createWallet,
}))

export default withData(Wallets)
