import React, { useState } from "react"
import { inject, observer } from "mobx-react"

const WalletToWallet = observer(({ wallet, walletToWallet }) => {
  const [amount, setAmount] = useState(0)
  const [cardNumber, setCardNumber] = useState("")
  const [isDisabled, setIsDisabled] = useState(true)

  const chgCardNumber = (e) => {
    setCardNumber(e.target.value)
    if (e.target.value.length === 9 && amount > 0) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }

  const sendMoney = () => {
    walletToWallet(wallet, cardNumber, amount)
  }

  const chgAmount = (e) => {
    if (
      /^(?=.*\d)\d*(?:\.\d{0,2})?$/.test(e.target.value) ||
      e.target.value === ""
    ) {
      setAmount(e.target.value)
      if (cardNumber.length === 9 && e.target.value > 0) {
        setIsDisabled(false)
      } else {
        setIsDisabled(true)
      }
    }
  }

  return (
    <>
      <div className="row">
        <div className="col">
          <input
            type="text"
            value={amount}
            onChange={chgAmount}
            className="form-control"
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <input
            type="text"
            value={cardNumber}
            onChange={chgCardNumber}
            className="form-control"
            placeholder="Enter wallet number"
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <button
            className="btn btn-primary btn-block"
            onClick={sendMoney}
            disabled={isDisabled}
          >
            Transfer
          </button>
        </div>
      </div>
    </>
  )
})

const withData = inject((stores) => ({
  walletToWallet: stores.WalletsStore.walletToWallet,
}))

export default withData(WalletToWallet)
