import { observable, action } from "mobx"
import Http from "../../helpers/http"
import { authApi } from "../../configs/api"

class WalletsStore {
  @observable isLoading = false
  @observable isError = false
  @observable errorMsg = null
  @observable walletsList = []
  @observable operationComplite = false
  @observable liqpayPaymentForm = ""

  @action
  getWallets = async () => {
    try {
      this.isLoading = true
      const response = await Http.get(`${authApi}v1/my-wallets`, true)
      this.walletsList = response.result
      this.isLoading = false
      this.isError = false
      this.errorMsg = null
    } catch (e) {
      this.isLoading = false
      this.isError = true
      this.errorMsg = e
    }
  }

  @action
  createWallet = async () => {
    try {
      const response = await Http.post(`${authApi}v1/create-wallet`, {}, true)
      const { id, wNumber } = response
      if (id && wNumber) {
        this.getWallets()
      }
    } catch (e) {
      this.isError = true
      this.errorMsg = e
    }
  }

  @action
  refillWallet = async (wallet, amount, recToken, comment) => {
    try {
      const response = await Http.post(
        `${authApi}v1/wayforpay/charge`,
        { wallet, amount, recToken, comment },
        true
      )
      const { status } = response
      if (status === "Success") {
        this.operationComplite(true)
        this.getWallets()
        setTimeout(() => this.operationComplite(false), 1000)
      }
    } catch (e) {
      this.isError = true
      this.errorMsg = e
    }
  }

  @action
  moneyOut = async (wallet, amount, recToken, comment) => {
    try {
      const response = await Http.post(
        `${authApi}v1/wayforpay/toCard`,
        { wallet, amount, recToken, comment },
        true
      )
      const { status } = response
      if (status === "accept") {
        this.operationComplite(true)
        this.getWallets()
        setTimeout(() => this.operationComplite(false), 1000)
      }
    } catch (e) {
      this.isError = true
      this.errorMsg = e
    }
  }

  @action
  liqpayPayment = async (amount, wallet, comment) => {
    this.liqpayPaymentForm = ""
    try {
      const response = await Http.post(
        `${authApi}v1/liqpay/payment`,
        { amount, wallet, comment },
        true
      )
      if (response.formCode) {
        this.liqpayPaymentForm = response.formCode.replace("\\", "")
      }
    } catch (e) {
      this.isError = true
      this.errorMsg = e
    }
  }

  @action
  liqpayOnCard = async (wallet, amount, comment, ccard) => {
    try {
      const response = await Http.post(
        `${authApi}v1/liqpay/onCard`,
        { wallet, amount, comment, ccard },
        true
      )
    } catch (e) {
      this.isError = true
      this.errorMsg = e
    }
  }

  @action
  walletToWallet = async (from, to, amount) => {
    try {
      const response = await Http.post(
        `${authApi}v1/wallet/towallet`,
        { from, to, amount },
        true
      )
      this.getWallets()
    } catch (e) {
      this.isError = true
      this.errorMsg = e
    }
  }
}

export default new WalletsStore()
