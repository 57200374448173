import React, { useState, useEffect } from "react"
import { inject, observer } from "mobx-react"
import Iframe from "react-iframe"

import { authApi } from "../../../configs/api"

const LiqPayPayment = observer(
  ({ wallet, liqpayPayment, liqpayPaymentForm }) => {
    const [amount, setAmount] = useState("0")
    const [isDisabled, setIsDisabled] = useState(true)
    const [showForm, setShowForm] = useState(false)

    const chgAmount = (e) => {
      setAmount(e.target.value)
      if (e.target.value >= 1) {
        setIsDisabled(false)
      } else {
        setIsDisabled(true)
      }
    }

    const hideIframe = (e) => {
      if (e.target.className === "iframe-close-if") {
        setShowForm(false)
      }
    }

    const tokenForQuery = localStorage.getItem("token").split(" ")

    return (
      <>
        {!showForm ? (
          <>
            <div className="row">
              <div className="col">
                <input
                  type="text"
                  value={amount}
                  onChange={chgAmount}
                  className="form-control"
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <button
                  className="btn btn-primary btn-block"
                  onClick={() => setShowForm(true)}
                  disabled={isDisabled}
                >
                  Replenish
                </button>
              </div>
            </div>
          </>
        ) : (
          <>
            <button
              className="btn btn-primary btn-block"
              onClick={() => setShowForm(false)}
            >
              Cancel
            </button>
            <Iframe
              url={`${authApi}v1/liqpay/payment?token=${tokenForQuery[1]}&comment=Replenish wallet ${wallet}&wallet=${wallet}&amount=${amount}`}
              className="iframeBlock"
            />
          </>
        )}
      </>
    )
  }
)

const withData = inject((stores) => ({
  liqpayPayment: stores.WalletsStore.liqpayPayment,
  liqpayPaymentForm: stores.WalletsStore.liqpayPaymentForm,
}))

export default withData(LiqPayPayment)
