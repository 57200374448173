import React, { useState, useEffect } from "react"
import { inject, observer } from "mobx-react"
import Iframe from "react-iframe"
import PerfectScrollbar from "react-perfect-scrollbar"
import { Table } from "antd"
import { RiDeleteBinLine } from "react-icons/ri"

import { authApi } from "../../configs/api"

import "react-perfect-scrollbar/dist/css/styles.css"
import "./CreditsCards.scss"

const CreditsCards = observer(
  ({ creditCardsList, isLoading, getCreditCards, deleteCard }) => {
    const [showForm, setShowForm] = useState(false)
    const columns = [
      {
        title: "Card",
        dataIndex: "cardPan",
      },
      {
        title: "",
        render: (record) => {
          return (
            <div className="wallet-control">
              <div className="wallet-icons">
                <RiDeleteBinLine onClick={() => deleteCard(record.id)} />
              </div>
            </div>
          )
        },
      },
    ]

    const hideIframe = (e) => {
      if (e.target.className === "iframe-close") {
        setShowForm(false)
      }
    }

    useEffect(() => {
      getCreditCards()
    }, [getCreditCards])

    const tokenForQuery = localStorage.getItem("token").split(" ")
    return (
      <>
        <div className="col-6">
          <div className="ccard-list">
            <div className="row">
              <div className="col">
                <button
                  className="btn btn-info"
                  onClick={() => setShowForm(true)}
                >
                  Add card
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={getCreditCards}
                  style={{ marginLeft: "20px" }}
                >
                  Refresh
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Table
                  columns={columns}
                  dataSource={creditCardsList}
                  loading={isLoading}
                  rowKey={(record) => record.id}
                />
              </div>
            </div>
          </div>
        </div>
        {showForm ? (
          <div className="iframe-close" onClick={hideIframe}>
            <div className="iframe-wrapper">
              <PerfectScrollbar>
                <Iframe
                  url={`${authApi}v1/wayforpay/addcard?token=${tokenForQuery[1]}`}
                  className="iframeBlock"
                />
              </PerfectScrollbar>
            </div>
          </div>
        ) : null}
      </>
    )
  }
)

const withData = inject((stores) => ({
  creditCardsList: stores.CreditsCardsStore.creditCardsList,
  isLoading: stores.CreditsCardsStore.isLoading,
  getCreditCards: stores.CreditsCardsStore.getCreditCards,
  deleteCard: stores.CreditsCardsStore.deleteCard,
}))

export default withData(CreditsCards)
